import { styled } from '@mui/material';

export const TextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),

  '& > .MuiTypography-root': {
    textAlign: 'center',
    color: theme.palette.white.main,
  },

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(1.5),
  },
}));
