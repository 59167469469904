import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getUserStatusTranslate } from './helpers';
import {
  InfoBlock,
  InfoBlockGrid,
  ProfilePageBottomButtons,
  ProfilePageHead,
  ProfilePageHeadButtons,
} from './styles';
import CommonButton from 'components/common/CommonButton';
import { MenuItemType } from 'components/common/DropdownMenu/DropdownMenu';
import { MoreActions } from 'components/common/MoreActions';
import { UserStatusChip } from 'components/pages/protected/Users/styles';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';
import { UserDetailsDto } from 'openapi-api/admin-service';

interface Props {
  userDetails?: UserDetailsDto;
  onEditUserClick?: () => void;
  actionsMenuItems?: MenuItemType[];
}

export const ProfileContent: FC<Props> = ({
  userDetails,
  onEditUserClick,
  actionsMenuItems = [],
}) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const historyLogLink = useMemo(() => {
    if (typeof userDetails?.id !== 'undefined') {
      return userDetails?.admin
        ? routePaths.users.adminList.profile(userDetails.id!).historyLog
        : routePaths.users.customerList.profile(userDetails.id!).historyLog;
    }
  }, [userDetails]);

  const buttons = useMemo(
    () => (
      <>
        {historyLogLink && (
          <Link to={historyLogLink}>
            <CommonButton variant="outlined">
              {t('pages.userProfile.historyLogButtonText')}
            </CommonButton>
          </Link>
        )}
        {!!actionsMenuItems.length && (
          <MoreActions title={userDetails?.username} items={actionsMenuItems} />
        )}
      </>
    ),
    [t, historyLogLink, userDetails?.username, actionsMenuItems],
  );

  const showEditDetailsButton = useMemo(() => {
    if (user.id === userDetails?.id) {
      return false;
    }

    return userDetails?.admin;
  }, [user.id, userDetails]);

  return (
    <>
      <ProfilePageHead>
        <Typography variant="h2">{userDetails?.username}</Typography>
        <ProfilePageHeadButtons>{buttons}</ProfilePageHeadButtons>
      </ProfilePageHead>
      <Box sx={{ mt: { mobile: 2, desktop: 0 } }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">
            {t('pages.userProfile.generalInfoTitle')}
          </Typography>
          {userDetails?.status && (
            <UserStatusChip status={userDetails.status}>
              {getUserStatusTranslate({ status: userDetails.status, t })}
            </UserStatusChip>
          )}
        </Box>
        <InfoBlock>
          <InfoBlockGrid isAdmin={userDetails?.admin}>
            <Typography variant="bodyMStrong">
              {userDetails?.admin
                ? t('username')
                : t('pages.users.table.masterAccountName')}
            </Typography>
            <Typography variant="bodyM">{userDetails?.username}</Typography>
            <Typography
              variant="bodyMStrong"
              sx={{ mt: { mobile: 2, desktop: 0 } }}
            >
              {t('role')}
            </Typography>
            <Typography variant="bodyM">
              {t(`userRoles.${userDetails?.userRole}`)}
            </Typography>
            {userDetails?.admin && (
              <>
                <Typography
                  variant="bodyMStrong"
                  sx={{ mt: { mobile: 2, desktop: 0 } }}
                >
                  {t('emailAddress')}
                </Typography>
                <Typography variant="bodyM">
                  {userDetails?.email || '-'}
                </Typography>
              </>
            )}
          </InfoBlockGrid>
          {showEditDetailsButton && (
            <CommonButton
              onClick={onEditUserClick}
              variant="outlined"
              size="small"
              sx={{ width: 'fit-content', backgroundColor: 'transparent' }}
            >
              {t('pages.userProfile.editInfoButtonText')}
            </CommonButton>
          )}
        </InfoBlock>
      </Box>
      <ProfilePageBottomButtons>{buttons}</ProfilePageBottomButtons>
    </>
  );
};
