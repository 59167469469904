import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  BottomPartContainer,
  ContentWrapper,
  FormValueLabel,
  InvestigationAreaIframe,
  InvestigationAreaIframeWrapper,
} from './styles';
import useAuth from 'contexts/auth';
import useComparisonChartContext from 'contexts/comparisonChart';
import useWindTurbinePanels from 'contexts/WindTurbinePanels';
import { PeriodFilterValues } from 'types/general';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { getGrafanaWidgetLink } from 'utils/functions/getGrafanaWidgetLink';

export const InvestigationAreaContent = () => {
  const { t } = useTranslation();
  const { accessData } = useAuth();
  const { panels } = useWindTurbinePanels();
  const { formValues } = useComparisonChartContext();
  const investigationAreaDateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
  const comparisonChartPanelId = 101;

  if (
    !formValues ||
    !formValues.assetName ||
    !formValues.assetPublicId ||
    (!(formValues.fromDate && formValues.toDate) &&
      !formValues.currentTimeFrame) ||
    !formValues.parameters.length
  ) {
    return (
      <BottomPartContainer>
        <Typography variant="h3" color="grey.600" textAlign="center">
          {t(
            'pages.comparisonChart.investigationArea.specifyAssetTimeFrameAndParameters',
          )}
        </Typography>
      </BottomPartContainer>
    );
  }

  const panelVariables = formValues.parameters.reduce(
    (
      vars: {
        [key: string]: number;
      },
      param: string,
    ) => ({
      ...vars,
      [param]: 1,
    }),
    {},
  );

  const investigationAreaWidgetLink = getGrafanaWidgetLink({
    panelId: comparisonChartPanelId.toString(),
    panelParameters: panels?.[comparisonChartPanelId] || {},
    authToken: accessData?.accessToken || '',
    from: formValues.fromDate
      ? getDateTimeInUnixMs(formValues.fromDate.toString())
      : PeriodFilterValues.current,
    to: formValues.toDate
      ? getDateTimeInUnixMs(formValues.toDate.toString())
      : 'now',
    shouldRefresh: formValues.currentTimeFrame,
    panelVariables,
  });

  return (
    <BottomPartContainer>
      <ContentWrapper>
        <Typography variant="h3">
          {t('pages.comparisonChart.investigationArea.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { desktop: 'row', mobile: 'column' },
            gap: { desktop: 4, mobile: 1 },
          }}
        >
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <FormValueLabel variant="bodyS" color="grey.900">
              {`${t('masterAccountName')}:`}
            </FormValueLabel>
            <Typography variant="bodySStrong" color="grey.900">
              {formValues.customerName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <FormValueLabel variant="bodyS" color="grey.900">
              {`${t('parkName')}:`}
            </FormValueLabel>
            <Typography variant="bodySStrong" color="grey.900">
              {formValues.locationName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <FormValueLabel variant="bodyS" color="grey.900">
              {`${t('pages.comparisonChart.form.windTurbineName')}:`}
            </FormValueLabel>
            <Typography variant="bodySStrong" color="grey.900">
              {formValues.assetName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <FormValueLabel variant="bodyS" color="grey.900">
              {`${t('timeFrame')}:`}
            </FormValueLabel>
            <Typography variant="bodySStrong" color="grey.900">
              {formValues.currentTimeFrame
                ? t('components.periodFilter.current')
                : `${dayjs(formValues.fromDate).format(
                    investigationAreaDateTimeFormat,
                  )} - ${dayjs(formValues.toDate).format(
                    investigationAreaDateTimeFormat,
                  )}`}
            </Typography>
          </Box>
        </Box>
        <InvestigationAreaIframeWrapper>
          <InvestigationAreaIframe src={investigationAreaWidgetLink} />
        </InvestigationAreaIframeWrapper>
      </ContentWrapper>
    </BottomPartContainer>
  );
};
