import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormValues } from './types';
import validationSchema from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import useApi from 'contexts/api';
import { useParkContext } from 'contexts/park';
import useResponsePopup from 'contexts/responsePopup';

interface Props extends IPopupProps {
  defaultValues: FormValues;
}

export const EditParkDetailsPopup: FC<Props> = ({
  isOpen,
  onClose,
  defaultValues,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const { locationControllerApi } = useApi();
  const { details, refetch } = useParkContext();
  const { openPopup, closePopup } = useResponsePopup();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (defaultValues.name && defaultValues.coordinates) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const handlePopupClose = useCallback(() => {
    reset(defaultValues);
    onClose();
  }, [reset, defaultValues, onClose]);

  const onSubmit = handleSubmit(async ({ name, coordinates }: FormValues) => {
    if (details?.locationId === undefined) return;

    const [latitude, longitude] = coordinates
      .replace(/ /g, '')
      ?.split(',')
      .map((coordinateStr) => Number(coordinateStr));

    try {
      await locationControllerApi.update({
        locationId: details.locationId,
        locationUpdateDto: {
          name,
          latitude,
          longitude,
        },
      });
      await refetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.parkDetails.editParkDetails.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;

        if (
          message.startsWith('Location with name') &&
          message.endsWith('already exists.')
        ) {
          setError('name', { message: t('form.errors.uniqueParkName') });
          return;
        }

        toast.error(message);
      }
    }
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={handlePopupClose}
      dataTestId="edit-park-details-popup"
    >
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 5 }}>
                {t('pages.parkDetails.editParkDetails.title')}
              </Typography>
              <TextField
                label={t('pages.parkDetails.editParkDetails.parkNameLabel')}
                fullWidth
                {...register('name')}
                testId={`${dataTestId}-name-input`}
                sx={{ mb: 4 }}
              />
              <TextField
                label={t('pages.parkDetails.editParkDetails.coordinatesLabel')}
                fullWidth
                {...register('coordinates')}
                testId={`${dataTestId}-coordinates-input`}
              />
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={!isDirty}
                type="submit"
                data-testid={`${dataTestId}-submit-button`}
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.save')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
