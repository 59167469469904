import { Typography, Box, ThemeProvider } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { defaultValues, getParametersOptions } from './constants';
import {
  FormWrapper,
  FormStyled,
  FormButtons,
  ClearButton,
  FieldsWrapperGeneral,
  FieldsWrapperTime,
  DateTimePickerStyled,
  ParametersFieldWrapper,
  CurrentTimeFrameFieldWrapper,
  TimeFrameSubtitleWrapper,
} from './styles';
import { ValueProps } from 'components/common/DatePicker/DateRangePicker/DateRangePicker';
import { FormSelect } from 'components/common/form/Select';
import Switch from 'components/common/form/Switch';
import {
  ParameterOption,
  ParametersSelect,
} from 'components/shared/ParametersSelect';
import useApi from 'contexts/api';
import useComparisonChartContext, {
  ComparisonChartFormValues,
} from 'contexts/comparisonChart';
import { AssetDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { darkTheme } from 'styles/theme';
import { SelectItem } from 'types/general';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const ComparisonChartForm: FC = () => {
  const { t } = useTranslation();
  const { customerControllerApi, locationControllerApi } = useApi();
  const { customers, setFormValues } = useComparisonChartContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const parametersOptions = getParametersOptions(t);
  const [parameters, setParameters] = useState<ParameterOption[]>([]);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const handleOpenParameters = useCallback(() => {
    setIsParametersOpen(true);
  }, []);
  const handleCloseParameters = useCallback(() => {
    setIsParametersOpen(false);
  }, []);

  const [
    customerNameParam,
    locationNameParam,
    assetNameParam,
    fromParam,
    toParam,
  ] = searchParams.values();

  const prefilledMode = useMemo(() => {
    if (
      customerNameParam &&
      locationNameParam &&
      assetNameParam &&
      fromParam &&
      toParam
    ) {
      return true;
    }
    return false;
  }, [
    assetNameParam,
    customerNameParam,
    fromParam,
    locationNameParam,
    toParam,
  ]);

  const form = useForm<ComparisonChartFormValues>({ defaultValues });
  const { watch, reset, setValue, formState } = form;
  const customerName = watch('customerName');
  const locationName = watch('locationName');
  const assetName = watch('assetName');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const currentTimeFrame = watch('currentTimeFrame');

  const getCustomerLocations = useCallback(async () => {
    if (!customerName) return [];

    try {
      return (await locationControllerApi.getAllForCustomer({ customerName }))
        .data;
    } catch {
      return [];
    }
  }, [customerName, locationControllerApi]);

  const { resource: customerLocations } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerLocations,
    disableGlobalLoader: true,
  });

  const getCustomerAssets = useCallback(async () => {
    const customerId = customers.find(
      (customer) => String(customer?.name) === customerName,
    )?.id;

    if (typeof customerId === 'undefined' || !locationName) return [];

    try {
      return (
        await customerControllerApi.getCustomerAssetDetails({
          customerId: Number(customerId),
        })
      ).data.filter(
        (asset) => asset.locationName && locationName === asset.locationName,
      );
    } catch {
      return [];
    }
  }, [customers, locationName, customerName, customerControllerApi]);

  const { resource: customerAssets } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerAssets,
    disableGlobalLoader: true,
  });

  const customerSelectItems = useMemo(
    (): SelectItem[] =>
      customers.map((customer) => ({
        label: String(customer.name),
        value: String(customer.name),
      })),
    [customers],
  );

  const customerLocationSelectItems = useMemo(
    () =>
      customerLocations.map(
        (location): SelectItem => ({
          label: location.name,
          value: location.name,
        }),
      ),
    [customerLocations],
  );

  const customerAssetSelectItems = useMemo(
    (): SelectItem[] =>
      customerAssets
        .filter(
          (asset) =>
            asset.status !== AssetDetailsDtoStatusEnum.CREATED &&
            asset.status !== AssetDetailsDtoStatusEnum.ONBOARDING,
        )
        .map(
          (asset): SelectItem => ({
            label: String(asset.turbineName),
            value: String(asset.turbineName),
          }),
        ),
    [customerAssets],
  );

  const onResetButtonClick = useCallback(() => {
    reset(defaultValues);
    setParameters([]);
    setFormValues(undefined);
  }, [reset, setFormValues]);

  const handleCurrentTimeFrameSelectChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setValue('currentTimeFrame', checked, {
        shouldDirty: true,
      });
      if (checked) {
        setValue('fromDate', '');
        setValue('toDate', '');
      }
    },
    [setValue],
  );

  const handleDateTimeChange = useCallback(
    (fieldName: ValueProps, value: Dayjs | null) => {
      const dateValue = value ? value.toISOString() : '';
      setValue(fieldName, dateValue, { shouldDirty: true });
    },
    [setValue],
  );

  const submitForm = useCallback(
    async (params: ParameterOption[]) => {
      const assetPublicId = customerAssets.find(
        (asset) => asset?.publicId && assetName === asset.turbineName,
      )?.publicId;

      if (
        !customerName ||
        !assetName ||
        !assetPublicId ||
        (!(fromDate && toDate) && !currentTimeFrame)
      )
        return;

      setFormValues({
        assetPublicId,
        assetName: assetName,
        customerName: customerName,
        locationName: locationName,
        fromDate: fromDate,
        toDate: toDate,
        currentTimeFrame: currentTimeFrame,
        parameters: params.map((p) => p.value),
      });
    },
    [
      assetName,
      currentTimeFrame,
      customerAssets,
      customerName,
      fromDate,
      locationName,
      setFormValues,
      toDate,
    ],
  );

  const selectParametersButtonsProps = useMemo(() => {
    return {
      onApply: (params: ParameterOption[]) => {
        setParameters(params);
        submitForm(params);
      },
      onClearAll: () => setParameters([]),
    };
  }, [submitForm]);

  useEffect(() => {
    setValue('locationName', '');
  }, [customerName, setValue]);

  useEffect(() => {
    setValue('assetName', '');
  }, [locationName, setValue]);

  useEffect(() => {
    if (prefilledMode && customers.length && !customerName) {
      setValue('customerName', customerNameParam, { shouldDirty: true });
      if (!fromDate) setValue('fromDate', fromParam, { shouldDirty: true });
      if (!toDate) setValue('toDate', toParam, { shouldDirty: true });
    }
  }, [
    customers,
    customerNameParam,
    prefilledMode,
    setValue,
    fromParam,
    toParam,
    customerName,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    if (prefilledMode && customerLocations.length && !locationName) {
      setValue('locationName', locationNameParam, { shouldDirty: true });
    }
  }, [
    customerLocations,
    locationName,
    locationNameParam,
    prefilledMode,
    setValue,
  ]);

  useEffect(() => {
    if (prefilledMode && customerAssets.length && !assetName) {
      setValue('assetName', assetNameParam, { shouldDirty: true });
      setSearchParams([]);
    }
  }, [
    customerAssets,
    assetName,
    assetNameParam,
    prefilledMode,
    setValue,
    setSearchParams,
  ]);

  const [maxFromTime, minToTime, maxToTime] = useMemo(() => {
    const localFromDate = fromDate ? dayjs(fromDate) : null;
    const localToDate = toDate ? dayjs(toDate) : null;

    let localMaxFromTime, localMinToTime, localMaxToTime;

    if (localFromDate && localFromDate.isSame(dayjs(), 'day')) {
      localMaxFromTime = dayjs();
    }
    if (localToDate && localToDate.isSame(dayjs(), 'day')) {
      localMaxToTime = dayjs();
    }

    if (
      localFromDate &&
      localToDate &&
      localFromDate.isSame(localToDate, 'day')
    ) {
      localMinToTime = localFromDate;
      localMaxFromTime = localToDate;
    }

    return [localMaxFromTime, localMinToTime, localMaxToTime];
  }, [fromDate, toDate]);

  const parametersFieldVisible = useMemo(() => {
    return !!(assetName && ((fromDate && toDate) || currentTimeFrame));
  }, [assetName, currentTimeFrame, fromDate, toDate]);

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <FormStyled>
          <Typography variant="h3" color="black.900">
            {t('pages.comparisonChart.form.title')}
          </Typography>
          <Box>
            <Typography variant="subheading" color="black.900">
              {t('pages.comparisonChart.form.generalInfoSubtitle')}
            </Typography>
            <FieldsWrapperGeneral sx={{ mt: 2.5 }}>
              <FormSelect
                label={t('masterAccount')}
                name="customerName"
                items={customerSelectItems}
                fullWidth
              />
              <FormSelect
                label={t('pages.comparisonChart.form.locationName')}
                name="locationName"
                items={customerLocationSelectItems}
                disabled={!customerName}
                fullWidth
              />
              <FormSelect
                label={t('pages.comparisonChart.form.windTurbineName')}
                name="assetName"
                items={customerAssetSelectItems}
                disabled={!locationName}
                fullWidth
              />
            </FieldsWrapperGeneral>
          </Box>
          <Box>
            <TimeFrameSubtitleWrapper>
              <Typography variant="subheading" color="black.900">
                {t('pages.comparisonChart.form.timePeriodSubtitle')}
              </Typography>
              <CurrentTimeFrameFieldWrapper>
                <Typography variant="bodyMStrong" color="black.900">
                  {t('pages.comparisonChart.form.currentTimeFrame')}
                </Typography>
                <Switch
                  name="currentTimeFrame"
                  checked={currentTimeFrame}
                  onChange={handleCurrentTimeFrameSelectChange}
                />
              </CurrentTimeFrameFieldWrapper>
            </TimeFrameSubtitleWrapper>
            <FieldsWrapperTime sx={{ mt: { tablet: 2.5, mobile: 1.5 } }}>
              <DateTimePickerStyled
                dateLabel={t('components.datePicker.from')}
                onChange={(value) => handleDateTimeChange('fromDate', value)}
                value={fromDate}
                maxDate={dayjs(toDate || undefined)}
                maxTime={maxFromTime}
                disabled={currentTimeFrame}
              />
              <Typography
                variant="bodyM"
                color="grey.700"
                sx={{ display: { mobile: 'none', desktop: 'block' } }}
              >
                {'-'}
              </Typography>
              <DateTimePickerStyled
                dateLabel={t('components.datePicker.to')}
                onChange={(value) => handleDateTimeChange('toDate', value)}
                value={toDate}
                minDate={(fromDate && dayjs(fromDate)) || undefined}
                maxDate={dayjs()}
                minTime={minToTime}
                maxTime={maxToTime}
                disabled={currentTimeFrame}
              />
            </FieldsWrapperTime>
          </Box>
          <FormButtons>
            <ClearButton
              variant="text"
              size="text"
              onClick={onResetButtonClick}
              disabled={!formState.isDirty}
            >
              {t('pages.comparisonChart.form.resetButton')}
            </ClearButton>
          </FormButtons>
          <ThemeProvider theme={darkTheme}>
            <ParametersFieldWrapper visible={parametersFieldVisible}>
              <ParametersSelect
                name="parameters"
                label={t('pages.comparisonChart.form.parameters')}
                options={parametersOptions}
                value={parameters}
                open={isParametersOpen}
                onOpen={handleOpenParameters}
                onClose={handleCloseParameters}
                buttonsProps={selectParametersButtonsProps}
                selectionLimit={5}
                fullWidth
                sx={{ ...(!parametersFieldVisible && { display: 'none' }) }}
              />
            </ParametersFieldWrapper>
          </ThemeProvider>
        </FormStyled>
      </FormProvider>
    </FormWrapper>
  );
};
