import { Typography } from '@mui/material';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import Notifications from '../components/pages/protected/Notifications';
import { Reports } from '../components/pages/protected/Reports';
import App from 'App';
import { ProtectedRoutes } from 'components/common/ProtectedRoutes';
import Login from 'components/pages/Login';
import {
  AdminUserContainer,
  AdminUserHistoryLog,
  AdminUserProfile,
} from 'components/pages/protected/AdminUser';
import { ComparisonChart } from 'components/pages/protected/ComparisonChart';
import { CustomerUserHistoryLog } from 'components/pages/protected/CustomerUserHistoryLog';
import DesignSystem from 'components/pages/protected/DesignSystem';
import { EmailVerification } from 'components/pages/protected/EmailVerification';
import { ErrorReport } from 'components/pages/protected/ErrorReport';
import Investigation from 'components/pages/protected/Investigation';
import InvestigationData from 'components/pages/protected/InvestigationData';
import { MainLayout } from 'components/pages/protected/MainLayout';
import {
  MasterAccountContainer,
  MasterAccountHistoryLog,
  MasterAccountProfile,
} from 'components/pages/protected/MasterAccount';
import { NotFound } from 'components/pages/protected/NotFound';
import { Park } from 'components/pages/protected/Park';
import { UnassignedWindTurbines } from 'components/pages/protected/Park/UnassignedWindTurbines';
import Parks from 'components/pages/protected/Parks';
import UpdateProfile from 'components/pages/protected/UpdateProfile';
import {
  AdminUsers,
  CustomerUsers,
  Users,
} from 'components/pages/protected/Users';
import ViewTagsPage from 'components/pages/protected/ViewTagsPage';
import {
  WindTurbine,
  WindTurbineGeneralInfo,
  WindTurbineStatuses,
  WindTurbineStreaming,
} from 'components/pages/protected/WindTurbine';
import { WindTurbineHistoryLog } from 'components/pages/protected/WindTurbineHistoryLog';
import routePaths from 'constants/routePaths';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';

export type HandleCustomType = {
  disallowedRoles?: UserDetailsDtoUserRoleEnum[];
};

export const appRoutes = [
  {
    path: routePaths.root,
    element: <Navigate replace to={routePaths.parks.root} />,
  },
  {
    path: routePaths.parks.root,
    children: [
      {
        path: routePaths.parks.root,
        element: <Parks />,
      },
      {
        path: routePaths.parks.specific(':locationId').root,
        children: [
          {
            path: routePaths.parks.specific(':locationId').root,
            element: <Park />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').root,
            element: <WindTurbine />,
            children: [
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId').root,
                element: <Navigate replace to="general" />,
              },
              {
                path: 'general',
                element: <WindTurbineGeneralInfo />,
              },
              {
                path: 'statuses',
                element: <WindTurbineStatuses />,
              },
              {
                path: 'streaming',
                element: <WindTurbineStreaming />,
              },
            ],
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').errorReport,
            element: <ErrorReport />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').historyLog.root,
            handle: {
              disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
            },
            children: [
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId').historyLog.root,
                element: <Navigate replace to="all" />,
              },
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId')
                  .historyLog.specific(':type'),
                element: <WindTurbineHistoryLog />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.parks.unassigned().root,
        children: [
          {
            path: routePaths.parks.unassigned().root,
            element: <UnassignedWindTurbines />,
          },
          {
            path: routePaths.parks.unassigned().windTurbine(':publicId').root,
            element: <WindTurbine />,
            children: [
              {
                path: routePaths.parks.unassigned().windTurbine(':publicId')
                  .root,
                element: <Navigate replace to="general" />,
              },
              {
                path: 'general',
                element: <WindTurbineGeneralInfo />,
              },
              {
                path: 'statuses',
                element: <WindTurbineStatuses />,
              },
              {
                path: 'streaming',
                element: <WindTurbineStreaming />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routePaths.design.main,
    children: [
      {
        path: routePaths.design.main,
        element: <DesignSystem />,
      },
      {
        path: routePaths.design.specificWT(':publicId'),
        element: <DesignSystem />,
      },
    ],
  },
  {
    path: routePaths.reports,
    element: <Reports />,
  },
  {
    path: routePaths.users.root,
    element: <Users />,
    handle: {
      disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
    },
    children: [
      {
        path: routePaths.users.adminList.root,
        element: <AdminUsers />,
      },
      {
        path: routePaths.users.customerList.root,
        element: <CustomerUsers />,
      },
    ],
  },
  {
    path: routePaths.users.adminList.profile(':userId').root,
    element: <AdminUserContainer />,
    handle: {
      disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
    },
    children: [
      {
        path: routePaths.users.adminList.profile(':userId').root,
        element: <AdminUserProfile />,
      },
      {
        path: routePaths.users.adminList.profile(':userId').historyLog,
        element: <AdminUserHistoryLog />,
      },
    ],
  },
  {
    path: routePaths.users.customerList.profile(':userId').root,
    element: <MasterAccountContainer />,
    handle: {
      disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
    },
    children: [
      {
        path: routePaths.users.customerList.profile(':userId').root,
        element: <MasterAccountProfile />,
      },
      {
        path: routePaths.users.customerList.profile(':userId').historyLog,
        element: <MasterAccountHistoryLog />,
      },
    ],
  },
  {
    path: routePaths.users.customerList.regularCustomerHistoryLog(':userId'),
    element: <CustomerUserHistoryLog />,
    handle: {
      disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
    },
  },
  {
    path: routePaths.notifications.root,
    children: [
      {
        path: routePaths.notifications.root,
        element: <Navigate replace to="all" />,
      },
      {
        path: routePaths.notifications.specific(':type'),
        element: <Notifications />,
      },
    ],
  },
  {
    path: routePaths.investigation.root,
    children: [
      {
        path: routePaths.investigation.root,
        element: <Investigation />,
      },
      {
        path: routePaths.investigation.specific(':requestId'),
        element: <InvestigationData />,
      },
    ],
  },
  {
    path: routePaths.comparisonChart.root,
    children: [
      {
        path: routePaths.comparisonChart.root,
        element: <ComparisonChart />,
      },
    ],
  },
  {
    path: routePaths.updateProfile,
    element: <UpdateProfile />,
  },
  {
    path: routePaths.viewTags(':publicId', ':errorTimestamp'),
    element: <ViewTagsPage />,
  },
  {
    path: routePaths.notFound,
    element: <NotFound />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const routes: RouteObject[] = [
  {
    path: routePaths.root,
    element: <App />,
    children: [
      {
        path: routePaths.root,
        element: <ProtectedRoutes />,
        children: [
          {
            path: routePaths.root,
            element: <MainLayout />,
            children: appRoutes,
          },
        ],
      },
      {
        path: routePaths.login,
        element: <Login />,
      },
      {
        path: routePaths.emailVerification(':verificationToken'),
        element: <EmailVerification />,
      },
      {
        path: '*',
        element: <Typography variant="h1">{'404 Not Found'}</Typography>,
      },
    ],
  },
];

export default routes;
