import {
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteRenderGroupParams,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import {
  FC,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ParameterGroup } from './constants';
import {
  ButtonsWrapper,
  CloseIconWrapper,
  CustomTemplateContentWrapper,
  GroupHeader,
  GroupItems,
  MenuItemStyled,
  MultipleSelectChip,
  SelectWrapper,
  TextFieldStyled,
  TopBlockWrapper,
} from './styles';
import { ParameterOption, ParametersSelectProps } from './types';
import CommonButton from 'components/common/CommonButton';
import {
  InformerBlock,
  InformerBlockVariants,
} from 'components/common/InformerBlock';
import { Checked, ChevronDownL, Close } from 'components/icons';

export const ParametersSelect: FC<ParametersSelectProps> = ({
  label,
  value: selectedOptions = [],
  options = [],
  fullWidth,
  onClose,
  disabled,
  buttonsProps,
  selectionLimit,
  customTemplate,
  sx,
  className,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [innerValues, setInnerValues] = useState(selectedOptions);
  const [showSelectionLimitError, setShowSelectionLimitError] = useState(false);

  useEffect(() => {
    setInnerValues(selectedOptions);
  }, [selectedOptions]);

  const isOptionSelected = useCallback(
    (optionValue?: string) =>
      !!innerValues.find((option) => option.value === optionValue),
    [innerValues],
  );

  const handleClose = useCallback(
    (event: SyntheticEvent, reason: AutocompleteCloseReason) => {
      onClose?.(event, reason);
      setInnerValues(selectedOptions);
      setShowSelectionLimitError(false);
    },
    [onClose, selectedOptions],
  );

  const handleOptionClick = useCallback(
    (clickedOption: ParameterOption, isSelected: boolean) => {
      if (
        !isSelected &&
        typeof selectionLimit !== 'undefined' &&
        innerValues.length >= selectionLimit
      ) {
        setShowSelectionLimitError(true);
        return innerValues;
      }

      setShowSelectionLimitError(false);

      let newValues = [];
      if (isSelected) {
        newValues = innerValues.filter(
          (parameter) => parameter.value !== clickedOption.value,
        );
      } else newValues = [...innerValues, clickedOption];

      setInnerValues(newValues);

      return newValues;
    },
    [innerValues, selectionLimit],
  );

  const renderTags = useCallback(
    (tags: ParameterOption[]) => {
      return (
        <>
          {tags.map((selectedOption) => (
            <MultipleSelectChip
              label={
                <>
                  {selectedOption.label}
                  <CloseIconWrapper
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      const newValues = tags.filter(
                        (tag) => tag.value !== selectedOption.value,
                      );
                      setInnerValues(newValues);
                      buttonsProps.onApply(newValues);
                    }}
                  >
                    <Close />
                  </CloseIconWrapper>
                </>
              }
              key={selectedOption.value}
              variant="outlined"
            />
          ))}
        </>
      );
    },
    [buttonsProps],
  );

  const renderGroup = useCallback(
    (params: AutocompleteRenderGroupParams) => {
      if (params.group === ParameterGroup.SELECT_TOP_BLOCK) {
        if (!showSelectionLimitError && !customTemplate) return null;

        return (
          <TopBlockWrapper key={params.key}>
            {showSelectionLimitError && (
              <InformerBlock
                variant={InformerBlockVariants.Error}
                title={t('pages.comparisonChart.form.selectionLimitError', {
                  selectionLimit,
                })}
                sx={{ width: '100%' }}
              />
            )}
            {!!customTemplate && (
              <InformerBlock
                variant={InformerBlockVariants.Success}
                sx={{
                  width: '100%',
                  flexDirection: { tablet: 'row' },
                }}
              >
                <CustomTemplateContentWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                    }}
                  >
                    <Typography variant="bodyMStrong">
                      {customTemplate.title}
                    </Typography>
                    <Typography variant="bodyS">
                      {customTemplate.subtitle}
                    </Typography>
                  </Box>
                  <CommonButton
                    size="small"
                    color="primary"
                    sx={{ width: 'fit-content', flexShrink: 0 }}
                    onClick={(e) => {
                      customTemplate.onSelect();
                      setShowSelectionLimitError(false);
                      onClose?.(e, 'blur');
                    }}
                  >
                    {customTemplate.buttonText}
                  </CommonButton>
                </CustomTemplateContentWrapper>
              </InformerBlock>
            )}
          </TopBlockWrapper>
        );
      }
      if (params.group === ParameterGroup.SELECT_BUTTONS && buttonsProps) {
        return (
          <ButtonsWrapper key={params.key}>
            <CommonButton
              onClick={(e) => {
                buttonsProps.onApply(innerValues);
                setShowSelectionLimitError(false);
                onClose?.(e, 'blur');
              }}
              disabled={!innerValues.length}
            >
              {t('components.select.apply')}
            </CommonButton>
            <CommonButton
              variant="text"
              size="large"
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setInnerValues([]);
                buttonsProps.onClearAll();
                setShowSelectionLimitError(false);
              }}
            >
              {t('components.select.clearAll')}
            </CommonButton>
          </ButtonsWrapper>
        );
      }
      return (
        <li key={params.key}>
          <GroupHeader>
            <Typography variant="bodyMStrong" textTransform="uppercase">
              {params.group}
            </Typography>
          </GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      );
    },
    [
      buttonsProps,
      customTemplate,
      innerValues,
      onClose,
      selectionLimit,
      showSelectionLimitError,
      t,
    ],
  );

  const renderOption = useCallback(
    (_: HTMLAttributes<HTMLLIElement>, option: ParameterOption) => {
      const isSelected = isOptionSelected(option.value);

      return (
        <MenuItemStyled
          key={option.value}
          value={option.value}
          selected={isSelected}
          onClick={() => handleOptionClick(option, isSelected)}
        >
          <Checkbox
            checked={isSelected}
            size="medium"
            disableRipple
            checkedIcon={<Checked />}
          />
          {option.label}
        </MenuItemStyled>
      );
    },
    [handleOptionClick, isOptionSelected],
  );

  return (
    <SelectWrapper
      disabled={disabled}
      sx={sx}
      className={className}
      fullWidth={fullWidth}
    >
      <Autocomplete
        options={options}
        value={selectedOptions}
        groupBy={(option) => option.group}
        renderGroup={renderGroup}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextFieldStyled
            label={label}
            {...params}
            placeholder={
              otherProps.open
                ? t('pages.comparisonChart.form.searchParameter')
                : undefined
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {typeof selectionLimit !== 'undefined' && (
                    <Typography
                      variant="bodyS"
                      color="grey.600"
                      whiteSpace="nowrap"
                      sx={{
                        position: 'absolute',
                        top: 'calc(50% - 10.5px)',
                        right: '43px',
                      }}
                    >
                      {t('components.select.maxN', {
                        n: selectionLimit,
                      })}
                    </Typography>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
              style:
                typeof selectionLimit !== 'undefined'
                  ? {
                      paddingRight: '95px',
                    }
                  : undefined,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderTags={renderTags}
        renderOption={renderOption}
        onClose={handleClose}
        disableClearable
        multiple
        popupIcon={<ChevronDownL />}
        componentsProps={{
          paper: {
            sx: () => ({
              borderRadius: '12px',
              boxShadow: `0px 5px 10px 0px rgba(30, 34, 40, 0.10), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,
              '& .MuiAutocomplete-listbox': {
                padding: 0,
                ...(options.length && {
                  maxHeight: '509px',
                  overflowY: 'auto',
                }),
              },
            }),
          },
        }}
        {...otherProps}
      />
    </SelectWrapper>
  );
};
