import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getPanelAssetItems } from './helpers';
import {
  AssetItem,
  ErrorsListWrapper,
  GridItem,
  IframeAggregatedTotal,
  IframePerfomanceDiagram,
  IframeTotalActiveEnergy,
  IframeTotalActiveEnergyWrapper,
  MobileBottomPanel,
  PerfomanceDiagramsWrapper,
  ReportContentHead,
  ReportPeriodChip,
  Wrapper,
} from './styles';
import { IParkToAssets, ReportWidgets, ReportWidgetsMobile } from './types';
import { GenerateReportButton } from '../GenerateReportButton';
import { MissingDataNotice } from '../MissingDataNotice';
import { InformerBlock } from 'components/common/InformerBlock';
import { OpenLink } from 'components/icons';
import useAuth from 'contexts/auth';
import useReport from 'contexts/report';
import { ReportRequestDtoReportFormatEnum } from 'openapi-api/report-service';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { getGrafanaWidgetLink } from 'utils/functions/getGrafanaWidgetLink';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

export const ReportContent: FC = () => {
  const { t } = useTranslation();
  const { accessData } = useAuth();
  const { reportValues, reportPanels, reportMissingDataIntervals } =
    useReport();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const unixReportPeriodFrom = useMemo(
    () => getDateTimeInUnixMs(reportValues?.period.from as string),
    [reportValues?.period.from],
  );

  const unixReportPeriodTo = useMemo(
    () => getDateTimeInUnixMs(reportValues?.period.to as string),
    [reportValues?.period.to],
  );

  const widgets = useMemo(
    () => (isDesktop ? ReportWidgets : ReportWidgetsMobile),
    [isDesktop],
  );

  const specificTotalActiveEnergyPanels = useMemo(() => {
    const assets = reportValues?.assets || [];
    const panels = reportPanels?.[widgets.SpecificTotalActiveEnergy] || [];
    return getPanelAssetItems(assets, panels);
  }, [reportValues?.assets, reportPanels, widgets.SpecificTotalActiveEnergy]);

  const specificPerfomanceDiagramPanels = useMemo(() => {
    const assets = reportValues?.assets || [];
    const panels = reportPanels?.[widgets.SpecificPerfomanceDiagram] || [];
    return getPanelAssetItems(assets, panels);
  }, [reportValues?.assets, reportPanels, widgets.SpecificPerfomanceDiagram]);

  const generateErrorLink = useCallback(
    (assetId?: string, assetLocationName?: string) => {
      if (!assetId) return;

      const urlParams = new URLSearchParams({
        from: unixReportPeriodFrom.toString(),
        to: unixReportPeriodTo.toString(),
        customer: reportValues?.customer.name as string,
      });

      const locationId = reportValues?.locations.find(
        (location) => location.name === assetLocationName,
      )?.id;

      return `${window.location.origin}${
        getWindTurbinePageLink({
          assetLocationId:
            typeof locationId !== 'undefined' ? String(locationId) : undefined,
          assetPublicId: assetId,
        }).errorReport
      }?${urlParams}`;
    },
    [reportValues, unixReportPeriodFrom, unixReportPeriodTo],
  );

  const parksToAssetsMapping = useMemo((): IParkToAssets => {
    if (!reportValues?.assets.length) return {};

    return reportValues?.assets.reduce(
      (parkWithAssets: IParkToAssets, asset) => {
        const locationName = String(asset.locationName);
        const locationAssets = parkWithAssets[locationName] || [];
        return {
          ...parkWithAssets,
          [locationName]: [...locationAssets, asset],
        };
      },
      {},
    );
  }, [reportValues?.assets]);

  if (!reportValues) return null;

  return (
    <>
      <Wrapper>
        <GridItem>
          <ReportContentHead>
            <Typography
              variant="h2"
              textAlign="left"
              sx={{ wordBreak: 'break-word' }}
            >
              <Trans
                i18nKey="pages.reports.content.title"
                values={{ customerName: reportValues.customer.name }}
              />
            </Typography>
            <Box display="flex" gap={3} flexShrink={0}>
              <ReportPeriodChip>
                <Typography variant="bodyM" color="grey.600">
                  {t('pages.reports.content.period')}
                </Typography>
                <Typography variant="bodyMStrong" color="black.900">
                  {dayjs(reportValues.period.from).format('DD/MM/YYYY')}
                  {' - '}
                  {dayjs(reportValues.period.to).format('DD/MM/YYYY')}
                </Typography>
              </ReportPeriodChip>
              {!!isDesktop && (
                <Box display="flex" gap={2}>
                  <GenerateReportButton
                    reportFormat={ReportRequestDtoReportFormatEnum.PDF}
                  />
                  <GenerateReportButton
                    reportFormat={ReportRequestDtoReportFormatEnum.CSV}
                  />
                </Box>
              )}
            </Box>
          </ReportContentHead>

          {dayjs(reportValues.period.to).isSame(dayjs(), 'day') && (
            <InformerBlock
              title={t('pages.reports.content.partialReportInformation')}
            >
              <Box ml={{ mobile: 0, desktop: 4.5 }}>
                <Typography variant="bodyS">
                  {t('pages.reports.content.partialReportInformationSubtitle')}
                </Typography>
              </Box>
            </InformerBlock>
          )}

          {!!reportMissingDataIntervals.length && (
            <MissingDataNotice
              missingDataIntervals={reportMissingDataIntervals}
            />
          )}

          {Object.entries(parksToAssetsMapping).map(([parkName, assets]) => (
            <Box display="flex" flexDirection="column" gap={3} key={parkName}>
              <Typography variant="h4" textAlign="left">
                {parkName}
              </Typography>
              <Box>
                <AssetItem variant="header">
                  <Typography variant="bodyS" color="grey.400">
                    {t('pages.reports.content.windTurbineName')}
                  </Typography>
                  <Typography variant="bodyS" color="grey.400">
                    {t('pages.reports.content.mac')}
                  </Typography>
                </AssetItem>
                <Box display="flex" flexDirection="column" gap="1px">
                  {assets.map((asset) => (
                    <AssetItem variant="grey" key={asset.id}>
                      <Typography variant="bodyM">
                        {asset.turbineName}
                      </Typography>
                      <Typography variant="bodyMStrong">
                        {asset.publicId}
                      </Typography>
                    </AssetItem>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </GridItem>

        <GridItem>
          <Typography variant="h3" textAlign="left">
            {t('pages.reports.content.aggregatedDataTitle')}
          </Typography>
          <Box>
            <IframeAggregatedTotal
              title={widgets.AggregatedTotal}
              src={getGrafanaWidgetLink({
                panelId: widgets.AggregatedTotal,
                panelParameters:
                  reportPanels?.[widgets.AggregatedTotal]?.[0] || {},
                authToken: accessData?.accessToken || '',
                from: unixReportPeriodFrom,
                to: unixReportPeriodTo,
              })}
            />
          </Box>
        </GridItem>

        <GridItem>
          <Typography variant="h3" textAlign="left">
            {t('pages.reports.content.occuredErrors')}
          </Typography>
          <Box>
            <AssetItem variant="header">
              <Typography variant="bodyS" color="grey.400">
                {t('pages.reports.content.windTurbineName')}
              </Typography>
            </AssetItem>
            <ErrorsListWrapper display="flex" flexDirection="column">
              {reportValues.assets.map((asset) => (
                <AssetItem variant="white" key={asset.id}>
                  <Typography variant="h5">{asset.turbineName}</Typography>
                  <Box
                    display="flex"
                    gap={1}
                    component="a"
                    href={generateErrorLink(asset.publicId, asset.locationName)}
                    target="_blank"
                  >
                    <OpenLink />
                    <Typography
                      variant="buttonPrimary"
                      sx={{
                        textTransform: 'none',
                        display: { mobile: 'none', desktop: 'block' },
                      }}
                      color="grey.800"
                    >
                      {t('pages.reports.content.showErrors')}
                    </Typography>
                  </Box>
                </AssetItem>
              ))}
            </ErrorsListWrapper>
          </Box>
        </GridItem>

        <GridItem>
          <Typography variant="h3" textAlign="left">
            {t('pages.reports.content.specificDataTitle')}
          </Typography>
          <Box>
            <AssetItem variant="header">
              <Typography variant="bodyS" color="grey.400">
                {t('pages.reports.content.windTurbineName')}
              </Typography>
              <Typography variant="bodyS" color="grey.400">
                {t('pages.reports.content.totalActiveEnergy')}
              </Typography>
            </AssetItem>
            <Box display="flex" flexDirection="column" gap="1px">
              {specificTotalActiveEnergyPanels.map(({ asset, panel }) => (
                <AssetItem variant="grey" key={asset.publicId}>
                  <Typography variant="bodyM">{asset.turbineName}</Typography>
                  <IframeTotalActiveEnergyWrapper>
                    <IframeTotalActiveEnergy
                      title={widgets.SpecificTotalActiveEnergy}
                      src={getGrafanaWidgetLink({
                        panelId: widgets.SpecificTotalActiveEnergy,
                        panelParameters: panel || {},
                        authToken: accessData?.accessToken || '',
                        from: unixReportPeriodFrom,
                        to: unixReportPeriodTo,
                      })}
                    />
                  </IframeTotalActiveEnergyWrapper>
                </AssetItem>
              ))}
            </Box>
          </Box>
          <PerfomanceDiagramsWrapper sx={{ mt: 1 }}>
            {specificPerfomanceDiagramPanels.map(({ asset, panel }) => (
              <Box key={asset.publicId}>
                <Typography variant="h5">
                  <Trans
                    i18nKey="pages.reports.content.performanceDiagramTitle"
                    values={{
                      windTurbineName: asset.turbineName,
                    }}
                  />
                </Typography>
                <Box sx={{ mt: 2.5 }}>
                  <IframePerfomanceDiagram
                    title={widgets.SpecificPerfomanceDiagram}
                    src={getGrafanaWidgetLink({
                      panelId: widgets.SpecificPerfomanceDiagram,
                      panelParameters: panel || {},
                      authToken: accessData?.accessToken || '',
                      from: unixReportPeriodFrom,
                      to: unixReportPeriodTo,
                    })}
                    height="156px"
                  />
                </Box>
              </Box>
            ))}
          </PerfomanceDiagramsWrapper>
        </GridItem>
      </Wrapper>
      {!isDesktop && (
        <MobileBottomPanel>
          <GenerateReportButton
            reportFormat={ReportRequestDtoReportFormatEnum.PDF}
          />
          <GenerateReportButton
            reportFormat={ReportRequestDtoReportFormatEnum.CSV}
          />
        </MobileBottomPanel>
      )}
    </>
  );
};
