import { ParameterGroup } from 'components/shared/ParametersSelect/constants';
import { TranslationFunction } from 'types/general';

export enum ParameterCategory {
  // ELPOWER
  LineToLineRmsGridVoltage = 'lineToLineRmsGridVoltage',
  RmsGridCurrent = 'rmsGridCurrent',
  LineToLineRmsGeneratorVoltage = 'lineToLineRmsGeneratorVoltage',
  RmsGeneratorCurrent = 'rmsGeneratorCurrent',
  InternalDCLinkVoltage = 'internalDCLinkVoltage',
  GridFrequency = 'gridFrequency',
  GeneratorFrequency = 'generatorFrequency',
  PowerFromGenerator = 'powerFromGenerator',
  HeatsinkTemperature = 'heatsinkTemperature',
  InternalCabinetTemperature = 'internalCabinetTemperature',
  TotalSupplyHours = 'totalSupplyHours',
  TotalGridPowerInjectionHours = 'totalGridPowerInjectionHours',
  StatusFaultFlag = 'statusFaultFlag',
  TotalEnergyDeliveredToGrid = 'totalEnergyDeliveredToGrid',
  StateMachineInverter = 'stateMachineInverter',
  StateMachineErrorManagement = 'stateMachineErrorManagement',
  WindSpeedMS10 = 'windSpeedMS10',
  DogvaneDirection = 'dogvaneDirection',
  AdditionalStatusFaultFlagFromGeneratorsInverter = 'additionalStatus',

  // JANITZA
  Voltage = 'voltage',
  CurrentI = 'currentI',
  CurrentN = 'currentN',
  RealPower = 'realPower',
  ActivePower = 'activePower',
  ApparentPower = 'apparentPower',
  TotalPower = 'totalPower',
  FundReactivePower = 'fundReactivePower',
  ReactivePower = 'reactivePower',
  CosPhi = 'cosPhi',
  MeasuredFrequency = 'measuredFrequency',
  RotationField = 'rotationField',
  RealEnergy = 'realEnergy',
  ApparentEnergy = 'apparentEnergy',
  ReactiveEnergy = 'reactiveEnergy',
  Harmonic = 'harmonic',

  Temperature = 'temperature',

  Mechanical = 'mechanical',
}

export const categoryToGroupMap = {
  [ParameterCategory.LineToLineRmsGridVoltage]: ParameterGroup.ELPOWER,
  [ParameterCategory.RmsGridCurrent]: ParameterGroup.ELPOWER,
  [ParameterCategory.LineToLineRmsGeneratorVoltage]: ParameterGroup.ELPOWER,
  [ParameterCategory.RmsGeneratorCurrent]: ParameterGroup.ELPOWER,
  [ParameterCategory.InternalDCLinkVoltage]: ParameterGroup.ELPOWER,
  [ParameterCategory.GridFrequency]: ParameterGroup.ELPOWER,
  [ParameterCategory.GeneratorFrequency]: ParameterGroup.ELPOWER,
  [ParameterCategory.PowerFromGenerator]: ParameterGroup.ELPOWER,
  [ParameterCategory.HeatsinkTemperature]: ParameterGroup.ELPOWER,
  [ParameterCategory.InternalCabinetTemperature]: ParameterGroup.ELPOWER,
  [ParameterCategory.TotalSupplyHours]: ParameterGroup.ELPOWER,
  [ParameterCategory.TotalGridPowerInjectionHours]: ParameterGroup.ELPOWER,
  [ParameterCategory.StatusFaultFlag]: ParameterGroup.ELPOWER,
  [ParameterCategory.TotalEnergyDeliveredToGrid]: ParameterGroup.ELPOWER,
  [ParameterCategory.StateMachineInverter]: ParameterGroup.ELPOWER,
  [ParameterCategory.StateMachineErrorManagement]: ParameterGroup.ELPOWER,
  [ParameterCategory.WindSpeedMS10]: ParameterGroup.ELPOWER,
  [ParameterCategory.DogvaneDirection]: ParameterGroup.ELPOWER,
  [ParameterCategory.AdditionalStatusFaultFlagFromGeneratorsInverter]:
    ParameterGroup.ELPOWER,

  [ParameterCategory.Voltage]: ParameterGroup.JANITZA,
  [ParameterCategory.CurrentI]: ParameterGroup.JANITZA,
  [ParameterCategory.CurrentN]: ParameterGroup.JANITZA,
  [ParameterCategory.RealPower]: ParameterGroup.JANITZA,
  [ParameterCategory.ActivePower]: ParameterGroup.JANITZA,
  [ParameterCategory.ApparentPower]: ParameterGroup.JANITZA,
  [ParameterCategory.TotalPower]: ParameterGroup.JANITZA,
  [ParameterCategory.FundReactivePower]: ParameterGroup.JANITZA,
  [ParameterCategory.ReactivePower]: ParameterGroup.JANITZA,
  [ParameterCategory.CosPhi]: ParameterGroup.JANITZA,
  [ParameterCategory.MeasuredFrequency]: ParameterGroup.JANITZA,
  [ParameterCategory.RotationField]: ParameterGroup.JANITZA,
  [ParameterCategory.RealEnergy]: ParameterGroup.JANITZA,
  [ParameterCategory.ApparentEnergy]: ParameterGroup.JANITZA,
  [ParameterCategory.ReactiveEnergy]: ParameterGroup.JANITZA,
  [ParameterCategory.Harmonic]: ParameterGroup.JANITZA,

  [ParameterCategory.Temperature]: ParameterGroup.TEMPERATURE,

  [ParameterCategory.Mechanical]: ParameterGroup.MECHANICAL,
};

export const getParametersCategoriesOptions = (t: TranslationFunction) => {
  return [
    // Select top block
    {
      label: '',
      value: ParameterGroup.SELECT_TOP_BLOCK,
      group: ParameterGroup.SELECT_TOP_BLOCK,
    },
    // ELPOWER:
    {
      label: t(
        'pages.investigationData.categories.elpower.lineToLineRmsGridVoltage',
      ),
      value: ParameterCategory.LineToLineRmsGridVoltage,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.rmsGridCurrent'),
      value: ParameterCategory.RmsGridCurrent,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.lineToLineRmsGeneratorVoltage',
      ),
      value: ParameterCategory.LineToLineRmsGeneratorVoltage,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.rmsGeneratorCurrent',
      ),
      value: ParameterCategory.RmsGeneratorCurrent,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.internalDCLinkVoltage',
      ),
      value: ParameterCategory.InternalDCLinkVoltage,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.gridFrequency'),
      value: ParameterCategory.GridFrequency,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.generatorFrequency'),
      value: ParameterCategory.GeneratorFrequency,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.powerFromGenerator'),
      value: ParameterCategory.PowerFromGenerator,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.heatsinkTemperature',
      ),
      value: ParameterCategory.HeatsinkTemperature,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.internalCabinetTemperature',
      ),
      value: ParameterCategory.InternalCabinetTemperature,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.totalSupplyHours'),
      value: ParameterCategory.TotalSupplyHours,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.totalGridPowerInjectionHours',
      ),
      value: ParameterCategory.TotalGridPowerInjectionHours,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.statusFaultFlag'),
      value: ParameterCategory.StatusFaultFlag,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.totalEnergyDeliveredToGrid',
      ),
      value: ParameterCategory.TotalEnergyDeliveredToGrid,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.stateMachineInverter',
      ),
      value: ParameterCategory.StateMachineInverter,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.stateMachineErrorManagement',
      ),
      value: ParameterCategory.StateMachineErrorManagement,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.windSpeedMS10'),
      value: ParameterCategory.WindSpeedMS10,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('pages.investigationData.categories.elpower.dogvaneDirection'),
      value: ParameterCategory.DogvaneDirection,
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'pages.investigationData.categories.elpower.additionalStatusFaultFlagFromGeneratorsInverter',
      ),
      value: ParameterCategory.AdditionalStatusFaultFlagFromGeneratorsInverter,
      group: ParameterGroup.ELPOWER,
    },
    // JANITZA:
    {
      label: t('pages.investigationData.categories.janitza.voltage'),
      value: ParameterCategory.Voltage,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.currentI'),
      value: ParameterCategory.CurrentI,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.currentN'),
      value: ParameterCategory.CurrentN,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.realPower'),
      value: ParameterCategory.RealPower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.activePower'),
      value: ParameterCategory.ActivePower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.apparentPower'),
      value: ParameterCategory.ApparentPower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.totalPower'),
      value: ParameterCategory.TotalPower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.fundReactivePower'),
      value: ParameterCategory.FundReactivePower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.reactivePower'),
      value: ParameterCategory.ReactivePower,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.cosPhi'),
      value: ParameterCategory.CosPhi,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.measuredFrequency'),
      value: ParameterCategory.MeasuredFrequency,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.rotationField'),
      value: ParameterCategory.RotationField,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.realEnergy'),
      value: ParameterCategory.RealEnergy,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.apparentEnergy'),
      value: ParameterCategory.ApparentEnergy,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.reactiveEnergy'),
      value: ParameterCategory.ReactiveEnergy,
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('pages.investigationData.categories.janitza.harmonic'),
      value: ParameterCategory.Harmonic,
      group: ParameterGroup.JANITZA,
    },
    // TEMPERATURE:
    {
      label: t('pages.investigationData.categories.temperature'),
      value: ParameterCategory.Temperature,
      group: ParameterGroup.TEMPERATURE,
    },
    // MECHANICAL:
    {
      label: t('pages.investigationData.categories.mechanical'),
      value: ParameterCategory.Mechanical,
      group: ParameterGroup.MECHANICAL,
    },
    // Select buttons
    {
      label: '',
      value: ParameterGroup.SELECT_BUTTONS,
      group: ParameterGroup.SELECT_BUTTONS,
    },
  ];
};

export const getCustomTemplateOptions = (t: TranslationFunction) => [
  {
    label: t('pages.investigationData.categories.elpower.generatorFrequency'),
    value: ParameterCategory.GeneratorFrequency,
    group: ParameterGroup.ELPOWER,
  },
  {
    label: t('pages.investigationData.categories.janitza.activePower'),
    value: ParameterCategory.ActivePower,
    group: ParameterGroup.JANITZA,
  },
  {
    label: t('pages.investigationData.categories.mechanical'),
    value: ParameterCategory.Mechanical,
    group: ParameterGroup.MECHANICAL,
  },
];

export const panelsMapping: {
  [key: string]: {
    panelId: number;
    tKey: string;
  }[];
} = {
  [ParameterCategory.LineToLineRmsGridVoltage]: [
    { panelId: 101, tKey: 'parameters.vrsAFErms' },
    { panelId: 102, tKey: 'parameters.vstAFErms' },
    { panelId: 103, tKey: 'parameters.vtrAFErms' },
  ],
  [ParameterCategory.RmsGridCurrent]: [
    { panelId: 107, tKey: 'parameters.irOUTrms' },
    { panelId: 108, tKey: 'parameters.isOUTrms' },
    { panelId: 109, tKey: 'parameters.itOUTrms' },
  ],
  [ParameterCategory.LineToLineRmsGeneratorVoltage]: [
    { panelId: 104, tKey: 'parameters.vrsINVrms' },
    { panelId: 105, tKey: 'parameters.vstINVrms' },
    { panelId: 106, tKey: 'parameters.vtrINVrms' },
  ],
  [ParameterCategory.RmsGeneratorCurrent]: [
    { panelId: 110, tKey: 'parameters.irINVrms' },
    { panelId: 111, tKey: 'parameters.isINVrms' },
    { panelId: 112, tKey: 'parameters.itINVrms' },
  ],
  [ParameterCategory.InternalDCLinkVoltage]: [
    { panelId: 98, tKey: 'parameters.dCBusVoltage' },
  ],
  [ParameterCategory.GridFrequency]: [
    { panelId: 100, tKey: 'parameters.freqAFEf' },
  ],
  [ParameterCategory.GeneratorFrequency]: [
    {
      panelId: 97,
      tKey: 'parameters.generatorFrequency',
    },
  ],
  [ParameterCategory.PowerFromGenerator]: [
    { panelId: 95, tKey: 'parameters.pinv' },
    { panelId: 96, tKey: 'parameters.pafe' },
  ],
  [ParameterCategory.HeatsinkTemperature]: [
    { panelId: 118, tKey: 'parameters.temperatureAFE' },
    {
      panelId: 119,
      tKey: 'parameters.temperatureInverter',
    },
  ],
  [ParameterCategory.InternalCabinetTemperature]: [
    {
      panelId: 120,
      tKey: 'parameters.cabinetTemperature',
    },
  ],
  [ParameterCategory.TotalSupplyHours]: [
    {
      panelId: 115,
      tKey: 'parameters.totalSupplyHours',
    },
  ],
  [ParameterCategory.TotalGridPowerInjectionHours]: [
    {
      panelId: 99,
      tKey: 'parameters.gridInjectionHours',
    },
  ],
  [ParameterCategory.StatusFaultFlag]: [
    {
      panelId: 176,
      tKey: 'parameters.gridVoltageAFESoftStartFLG1AFEHEX1B723',
    },
    {
      panelId: 177,
      tKey: 'parameters.vectorModulationFLG1AFEHEX1B622',
    },
    {
      panelId: 178,
      tKey: 'parameters.waitForWindFLG1AFEHEX1B521',
    },
    {
      panelId: 179,
      tKey: 'parameters.waitForGridReConnectionTimeFLG1AFEHEX0B319',
    },
    {
      panelId: 180,
      tKey: 'parameters.gridAFEContractorOpenFLG1AFEHEX0B218',
    },
    {
      panelId: 181,
      tKey: 'parameters.permanentFaultFLG1AFEHEX3B1531',
    },
    {
      panelId: 182,
      tKey: 'parameters.temporaryFaultFLG1AFEHEX3B1430',
    },
    {
      panelId: 183,
      tKey: 'parameters.faultForConsecutiveTemporaryFaultFLG1AFEHEX3B1329',
    },
    {
      panelId: 184,
      tKey: 'parameters.gridFrequencyBeyondLimitsFLG1AFEHEX3B1228',
    },
    {
      panelId: 185,
      tKey: 'parameters.externalGridProtectionTripg59ForUKFLG1AFEHEX2B1026',
    },
    {
      panelId: 186,
      tKey: 'parameters.voltageBeyondLimitsFLG1AFEHEX2B925',
    },
    {
      panelId: 187,
      tKey: 'parameters.wrongGridsPhaseSequenceFLG1AFEHEX2B824',
    },
    {
      panelId: 188,
      tKey: 'parameters.gridVoltageVIAFENotBalancedFLG1AFEHEX0B117',
    },
    {
      panelId: 189,
      tKey: 'parameters.gridCurrentLiAFENotBalancedFLG1AFEHEX0B016',
    },

    {
      panelId: 190,
      tKey: 'parameters.gridPreChargeSelectionFLGAFEHEX3B1515',
    },
    {
      panelId: 805,
      tKey: 'parameters.gridPLLNonLockedFLGAFEHEX3B1414',
    },
    {
      panelId: 804,
      tKey: 'parameters.stopBitLogicsFLGAFEHEX0B11',
    },
    {
      panelId: 803,
      tKey: 'parameters.boostDCFromAFEInProgressFLGAFEHEX0B00',
    },
    {
      panelId: 802,
      tKey: 'parameters.vbusUnderThresholdFLGAFEHEX3B1313',
    },
    {
      panelId: 801,
      tKey: 'parameters.vrmsAFEBeyondSuperiorLimitFLGAFEHEX3B1212',
    },
    {
      panelId: 800,
      tKey: 'parameters.iGBTOverheatingThermalSwitchTripFLGAFEHEX2B1111',
    },
    {
      panelId: 799,
      tKey: 'parameters.faultFeedbackFromGridAFEContactorFLGAFEHEX2B99',
    },
    {
      panelId: 798,
      tKey: 'parameters.faultLineGridUnderMinimumLimitLVFNonRTFLGAFEHEX2B88',
    },
    {
      panelId: 797,
      tKey: 'parameters.faultForMaxGridVoltageSoftwareVlnISwFLGAFEHEX1B77',
    },
    {
      panelId: 796,
      tKey: 'parameters.faultForMaxGridCurrentSoftwareLlnISwFLGAFEHEX1B66',
    },
    {
      panelId: 795,
      tKey: 'parameters.hZFaultHwInternalControlCardHardwareProblemFLGAFEHEX1B55',
    },
    {
      panelId: 794,
      tKey: 'parameters.driverAFEFaultHwInternalIgbtDriverProtectionFLGAFEHEX1B44',
    },
    {
      panelId: 793,
      tKey: 'parameters.dCLinkBrakingChopperFaultFLGAFEHEX0B33',
    },
    {
      panelId: 811,
      tKey: 'parameters.faultForMaxLineCurrentHardwareLlnIHwFLGAFEHEX0B22',
    },

    {
      panelId: 901,
      tKey: 'parameters.vectorModulationOnFLG1INVHEX1B622',
    },
    {
      panelId: 792,
      tKey: 'parameters.startCommandHardwareOnFLG1INVHEX0B218',
    },
    {
      panelId: 812,
      tKey: 'parameters.permanentFaultFLG1INVHEX3B1531',
    },
    {
      panelId: 813,
      tKey: 'parameters.temporaryFaultFLG1INVHEX3B1430',
    },
    {
      panelId: 814,
      tKey: 'parameters.mechanicStopFailedFLG1INVHEX2B1127',
    },
    {
      panelId: 815,
      tKey: 'parameters.softwareI2tGeneratorProtectionFLG1INVHEX1B521',
    },
    {
      panelId: 816,
      tKey: 'parameters.faultContactorsRelayDriverCtrlBoardErrorFLG1INVHEX1B420',
    },
    {
      panelId: 900,
      tKey: 'parameters.maxTurbineOverspeedFLG1INVHEX0B319',
    },
    {
      panelId: 817,
      tKey: 'parameters.generatorVoltageViINVUnbalancedFLG1INVHEX0B117',
    },
    {
      panelId: 818,
      tKey: 'parameters.generatorCurrentLiINVUnbalancedFLG1INVHEX0B016',
    },

    {
      panelId: 830,
      tKey: 'parameters.stopBitLogicsFLGINVHEX0B11',
    },
    {
      panelId: 829,
      tKey: 'parameters.setupNotCompatibleWithFwReleaseFaultFLGINVHEX3B1515',
    },
    {
      panelId: 828,
      tKey: 'parameters.generatorVoltageVrmsINVUnderThresholdFLGINVHEX3B1414',
    },
    {
      panelId: 902,
      tKey: 'parameters.generatorFrequencyBeyondLimitsFLGINVHEX3B1313',
    },
    {
      panelId: 827,
      tKey: 'parameters.preChargeDCLinkFailedFLGINVHEX2B1010',
    },
    {
      panelId: 826,
      tKey: 'parameters.faultFeedbackFromGeneratorINVContactorFLGINVHEX2B99',
    },
    {
      panelId: 825,
      tKey: 'parameters.faultForMaxDCLinkBusVoltageSoftwareVbusSwFLGINVHEX2B88',
    },
    {
      panelId: 824,
      tKey: 'parameters.faultForMaxGeneratorVoltageSoftwareVinvISwFLGINVHEX1B77',
    },
    {
      panelId: 822,
      tKey: 'parameters.faultForMaxGeneratorCurrentSoftwareLinvISwFLGINVHEX1B66',
    },
    {
      panelId: 821,
      tKey: 'parameters.driverINVFaultHwFLGINVHEX1B44',
    },
    {
      panelId: 820,
      tKey: 'parameters.faultPerMaxVbusHwFLGINVHEX0B33',
    },
    {
      panelId: 819,
      tKey: 'parameters.faultPerMaxLinvIHwFLGINVHEX0B22',
    },
  ],
  [ParameterCategory.TotalEnergyDeliveredToGrid]: [
    {
      panelId: 116,
      tKey: 'parameters.dailyTotalEnergy',
    },
    {
      panelId: 117,
      tKey: 'parameters.monthlyTotalEnergy',
    },
    {
      panelId: 113,
      tKey: 'parameters.annualTotalEnergy',
    },
    { panelId: 114, tKey: 'parameters.totalEnergy' },
  ],
  [ParameterCategory.StateMachineInverter]: [
    {
      panelId: 123,
      tKey: 'parameters.stateMachineInverterStMach',
    },
  ],
  [ParameterCategory.StateMachineErrorManagement]: [
    {
      panelId: 124,
      tKey: 'parameters.stateMachineErrorManagementStError',
    },
  ],
  [ParameterCategory.WindSpeedMS10]: [
    {
      panelId: 125,
      tKey: 'parameters.instantaneousWindSpeed',
    },
    {
      panelId: 126,
      tKey: 'parameters.dailyAverageWindSpeed',
    },
    {
      panelId: 127,
      tKey: 'parameters.monthlyAverageWindSpeed',
    },
    {
      panelId: 128,
      tKey: 'parameters.annualAverageWindSpeed',
    },
  ],
  [ParameterCategory.DogvaneDirection]: [
    {
      panelId: 129,
      tKey: 'parameters.dogvaneDirection',
    },
  ],
  [ParameterCategory.AdditionalStatusFaultFlagFromGeneratorsInverter]: [
    {
      panelId: 840,
      tKey: 'parameters.generatorStartFailedFLG2INVHEX0B032',
    },

    {
      panelId: 842,
      tKey: 'parameters.gridSwitchTrippedFLG2AFEHEX0B335',
    },
    {
      panelId: 844,
      tKey: 'parameters.maxNumberOfConsecutiveLVFRTFLG2AFEHEX1B638',
    },
    {
      panelId: 903,
      tKey: 'parameters.lVFAnalysisInProgressStError100FLG2AFEHEX1B537',
    },
    {
      panelId: 841,
      tKey: 'parameters.faultAntiIslandingDetectionInterventionFLG2AFEHEX2B941',
    },
    {
      panelId: 846,
      tKey: 'parameters.limitationActivePowerFromOuterSetPointFLG2AFEHEX0B133',
    },
    {
      panelId: 847,
      tKey: 'parameters.limitationActivePowerForFrequencyFLG2AFEHEX0B032',
    },
    {
      panelId: 845,
      tKey: 'parameters.returnSignalStarTriangleUD1FLG2AFEHEX1B436',
    },
    {
      panelId: 848,
      tKey: 'parameters.emulatedSPILimit5951FLG2AFEHEX0B234',
    },
  ],
  [ParameterCategory.Voltage]: [
    { panelId: 68, tKey: 'parameters.voltageL1N' },
    { panelId: 69, tKey: 'parameters.voltageL2N' },
    { panelId: 70, tKey: 'parameters.voltageL3N' },
    { panelId: 71, tKey: 'parameters.voltageL1L2' },
    { panelId: 72, tKey: 'parameters.voltageL2L3' },
    { panelId: 73, tKey: 'parameters.voltageL1L3' },
  ],
  [ParameterCategory.CurrentI]: [
    { panelId: 74, tKey: 'parameters.currentL1' },
    { panelId: 75, tKey: 'parameters.currentL2' },
    { panelId: 76, tKey: 'parameters.currentL3' },
  ],
  [ParameterCategory.CurrentN]: [{ panelId: 77, tKey: 'parameters.currentN' }],
  [ParameterCategory.RealPower]: [
    {
      panelId: 138,
      tKey: 'parameters.activePhasePowerP1',
    },
    {
      panelId: 135,
      tKey: 'parameters.activePhasePowerP2',
    },
    {
      panelId: 137,
      tKey: 'parameters.activePhasePowerP3',
    },
  ],
  [ParameterCategory.ActivePower]: [
    { panelId: 63, tKey: 'parameters.activePower' },
  ],
  [ParameterCategory.ApparentPower]: [
    {
      panelId: 915,
      tKey: 'parameters.totalPhasePowerS1',
    },
    {
      panelId: 916,
      tKey: 'parameters.totalPhasePowerS2',
    },
    {
      panelId: 917,
      tKey: 'parameters.totalPhasePowerS3',
    },
  ],
  [ParameterCategory.TotalPower]: [
    { panelId: 65, tKey: 'parameters.totalPower' },
  ],
  [ParameterCategory.FundReactivePower]: [
    {
      panelId: 141,
      tKey: 'parameters.reactivePhasePowerQ1',
    },
    {
      panelId: 142,
      tKey: 'parameters.reactivePhasePowerQ2',
    },
    {
      panelId: 143,
      tKey: 'parameters.reactivePhasePowerQ3',
    },
  ],
  [ParameterCategory.ReactivePower]: [
    { panelId: 64, tKey: 'parameters.reactivePower' },
  ],
  [ParameterCategory.CosPhi]: [
    { panelId: 144, tKey: 'parameters.cosPhiL1' },
    { panelId: 145, tKey: 'parameters.cosPhiL2' },
    { panelId: 146, tKey: 'parameters.cosPhiL3' },
  ],
  [ParameterCategory.MeasuredFrequency]: [
    { panelId: 67, tKey: 'parameters.frequency' },
  ],
  [ParameterCategory.RotationField]: [
    { panelId: 147, tKey: 'parameters.rotationField' },
  ],
  [ParameterCategory.RealEnergy]: [
    {
      panelId: 148,
      tKey: 'parameters.totalPhaseActiveEnergyL1',
    },
    {
      panelId: 149,
      tKey: 'parameters.totalPhaseActiveEnergyL2',
    },
    {
      panelId: 150,
      tKey: 'parameters.totalPhaseActiveEnergyL3',
    },
    {
      panelId: 79,
      tKey: 'parameters.totalActiveEnergy',
    },
    {
      panelId: 154,
      tKey: 'parameters.totalPhaseActiveEnergyConsumedL1',
    },
    {
      panelId: 151,
      tKey: 'parameters.totalPhaseActiveEnergyConsumedL2',
    },
    {
      panelId: 153,
      tKey: 'parameters.totalPhaseActiveEnergyConsumedL3',
    },
    {
      panelId: 80,
      tKey: 'parameters.totalActiveEnergyConsumed',
    },
    {
      panelId: 157,
      tKey: 'parameters.totalPhaseActiveEnergyDeliveredL1',
    },
    {
      panelId: 152,
      tKey: 'parameters.totalPhaseActiveEnergyDeliveredL2',
    },
    {
      panelId: 918,
      tKey: 'parameters.totalPhaseActiveEnergyDeliveredL3',
    },
    {
      panelId: 81,
      tKey: 'parameters.totalActiveEnergyDelivered',
    },
  ],
  [ParameterCategory.ApparentEnergy]: [
    {
      panelId: 155,
      tKey: 'parameters.totalPhaseApparentEnergyL1',
    },
    {
      panelId: 904,
      tKey: 'parameters.totalPhaseApparentEnergyL2',
    },
    {
      panelId: 159,
      tKey: 'parameters.totalPhaseApparentEnergyL3',
    },
    {
      panelId: 82,
      tKey: 'parameters.totalApparentEnergy',
    },
  ],
  [ParameterCategory.ReactiveEnergy]: [
    {
      panelId: 160,
      tKey: 'parameters.totalPhaseReactiveEnergyL1',
    },
    {
      panelId: 905,
      tKey: 'parameters.totalPhaseReactiveEnergyL2',
    },
    {
      panelId: 906,
      tKey: 'parameters.totalPhaseReactiveEnergyL3',
    },
    {
      panelId: 83,
      tKey: 'parameters.totalReactiveEnergy',
    },
    {
      panelId: 163,
      tKey: 'parameters.totalPhaseReactiveEnergyIndL1',
    },
    {
      panelId: 907,
      tKey: 'parameters.totalPhaseReactiveEnergyIndL2',
    },
    {
      panelId: 908,
      tKey: 'parameters.totalPhaseReactiveEnergyIndL3',
    },
    {
      panelId: 84,
      tKey: 'parameters.totalReactiveEnergyInd',
    },
    {
      panelId: 167,
      tKey: 'parameters.totalReactiveEnergyCapL1',
    },
    {
      panelId: 909,
      tKey: 'parameters.totalReactiveEnergyCapL2',
    },
    {
      panelId: 910,
      tKey: 'parameters.totalReactiveEnergyCapL3',
    },
    {
      panelId: 85,
      tKey: 'parameters.totalReactiveEnergyCap',
    },
  ],
  [ParameterCategory.Harmonic]: [
    { panelId: 171, tKey: 'parameters.harmonicTHDUL1N' },
    { panelId: 911, tKey: 'parameters.harmonicTHDUL2N' },
    { panelId: 912, tKey: 'parameters.harmonicTHDUL3N' },
    { panelId: 174, tKey: 'parameters.harmonicTHDIL1' },
    { panelId: 913, tKey: 'parameters.harmonicTHDIL2' },
    { panelId: 914, tKey: 'parameters.harmonicTHDIL3' },
  ],
  [ParameterCategory.Temperature]: [
    {
      panelId: 87,
      tKey: 'parameters.temperatureGenPhaseA',
    },
    {
      panelId: 88,
      tKey: 'parameters.temperatureGenPhaseB',
    },
    {
      panelId: 89,
      tKey: 'parameters.temperatureGenPhaseC',
    },
    { panelId: 90, tKey: 'parameters.temperaturePLCBox' },
    {
      panelId: 91,
      tKey: 'parameters.temperatureDCResistor',
    },
    {
      panelId: 92,
      tKey: 'parameters.temperatureBrakeResistor',
    },
    {
      panelId: 93,
      tKey: 'parameters.temperatureUZBoxBack',
    },
    {
      panelId: 94,
      tKey: 'parameters.temperatureUZBoxFront',
    },
  ],
  [ParameterCategory.Mechanical]: [
    { panelId: 12, tKey: 'parameters.windSpeed' },
    { panelId: 121, tKey: 'parameters.generatorSpeed' },
  ],
};
